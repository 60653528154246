import React, { Component } from 'react'
import { Redirect, Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import NotFound from '../view/NotFound/NotFound'
import routesConfig, { routeConfigType, escapeRouteConfig } from './config'
import ManageSkeleton from '../components/Global/ManageSkeleton/ManageSkeleton'
import { deepCopy, getWindowStorage } from 'src/utils/tools'
import { connect } from 'react-redux'
import Login from 'src/view/Login/Login'
import { UserRoleEnum } from './enumType'
// const createBrowserHistory = require('history').createBrowserHistory
interface RouteConfigState {
    logged: boolean
    isAdmin: boolean
    routeArr: routeConfigType[]
    escapeRouteArr: routeConfigType[]
    hideMenu: string[]
}
class RouteConfig extends Component<any, RouteConfigState> {
    constructor(props) {
        super(props)
        this.state = {
            logged: true, // 是否登录
            routeArr: [],
            escapeRouteArr: [],
            isAdmin: false,
            hideMenu: [],
        }
    }
    static getDerivedStateFromProps(props) {
        const role = getWindowStorage('role') as UserRoleEnum,
            token = getWindowStorage('token')
        let copyRoutesConfig = deepCopy(routesConfig) as { [key: string]: routeConfigType[] }
        // current = copyRoutesConfig.find(v => v.route === '/manage'),
        let hideMenu = []
        let filterRoutesConfig = Object.entries(copyRoutesConfig).reduce(
            (allRoutes, [key, val]) => {
                let filterArr = val.filter(item => {
                    item.route = key + item.route
                    if (role) {
                        if (item.children) {
                            item.children = item.children.filter(val => val.role?.includes(role))
                        }
                        return item.role?.includes(role)
                    } else {
                        return true
                    }
                })
                return allRoutes.concat(filterArr)
            },
            [],
        )
        let escapeRouteArr = Object.entries(deepCopy(escapeRouteConfig)).reduce(
            (allRoutes, [key, val]: any) => {
                let filterArr = val.map(item => {
                    item.route = key + item.route
                    return item
                })
                return allRoutes.concat(filterArr)
            },
            [],
        )
        switch (role) {
            case UserRoleEnum.Adminer: {
                break
            }
            case UserRoleEnum.User: {
                for (let k in props.userConfigStore?.MenusSwitch) {
                    if (props.userConfigStore?.MenusSwitch?.[k] === 'OFF') {
                        hideMenu.push(k)
                    }
                }
                break
            }
        }
        return {
            escapeRouteArr: escapeRouteArr,
            routeArr: filterRoutesConfig,
            isAdmin: role === UserRoleEnum.Adminer,
            hideMenu,
            logged: !!role && !!token,
        }
    }
    setRouteList = (arr, routeList) => {
        arr.forEach(r => {
            if (!r.children) {
                routeList.push(r)
            } else {
                if (this.state.hideMenu.includes(r.key)) return
                let parentFix =
                    r.route === '/' || r.mode === 'blank' ? '' : (r.parentFix || '') + r.route
                r.children.forEach(v => {
                    v.parentFix = parentFix
                })
                this.setRouteList(r.children, routeList)
            }
        })
    }
    render() {
        const { logged, routeArr, escapeRouteArr } = this.state
        const loginPath = '/login'
        const routeList = [],
            escapeRouteList = []
        this.setRouteList(routeArr, routeList)
        this.setRouteList(escapeRouteArr, escapeRouteList)
        const ManageSkeletonRoutes = routeList
            .filter(
                v =>
                    !this.state.hideMenu.includes(v.key) &&
                    !this.state.hideMenu.includes(v.parentKey),
            )
            .map(v => (v.parentFix || '') + v.route)
        return (
            <div className="mainApp">
                <Router>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                let defaultPage
                                if (!logged) {
                                    defaultPage = loginPath
                                } else {
                                    defaultPage = ManageSkeletonRoutes[0] || loginPath
                                }
                                return <Redirect to={defaultPage} push />
                            }}
                        ></Route>
                        <Route path="/login" component={Login} />
                        <Route path="/404" exact component={NotFound} />
                        <Route
                            path={ManageSkeletonRoutes}
                            render={() => {
                                // DEBUG:
                                return logged ? (
                                    <ManageSkeleton
                                        routeArr={this.state.routeArr}
                                        hideMenu={this.state.hideMenu}
                                        loading={this.props.loading}
                                    />
                                ) : (
                                    <Redirect to={loginPath} />
                                )
                            }}
                        ></Route>
                        <Route
                            path={escapeRouteList.map(v => (v.parentFix || '') + v.route)}
                            render={() => {
                                return (
                                    <div className="contentWrap">
                                        <div className="page-content"></div>
                                    </div>
                                )
                            }}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </div>
        )
    }
}
function getFirstRouter(routeArr: routeConfigType[]) {
    const user = getWindowStorage('user') || '{}'
    if (Array.isArray(routeArr[0]?.children)) {
        return routeArr[0].route + getFirstRouter(routeArr[0].children)
    }
    return routeArr[0]?.route || ''
}
export default connect((state: any) => {
    return {
        userConfigStore: state.userConfigStore,
    }
})(RouteConfig)
