import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import Icon, { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import ContentLoader from 'react-content-loader'
import HeaderCustom from '../HeaderCustom/HeaderCustom'
import './ManageSkeleton.scss'
import { getWindowStorage } from 'src/utils/tools'
import { UserRoleEnum } from 'src/router/enumType'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
// import store from 'src/redux/store'

const hostname = window.location.hostname
const { Sider } = Layout
const isDevOrTest = hostname === '114.55.41.71' || process.env.NODE_ENV === 'development'

function ManageSkeleton(props) {
    return (
        <Layout style={{ height: '100%', maxHeight: '100%' }} className="manageGlobalSkeleton">
            <SiderMenu {...props} />
            <Layout className="contentWrap">
                <HeaderCustom {...props} />
                <BreadCrumbs {...props} />
                {props.loading && (
                    <ContentLoader
                        backgroundColor="#fff"
                        foregroundColor="#f0f2f5"
                        style={{ padding: 24 }}
                    ></ContentLoader>
                )}
                <div className="page-content"></div>
            </Layout>
        </Layout>
    )
}

const SiderCustom = React.memo<any>(props => {
    const { routeArr, hideMenu } = props
    const url = decodeURI(JSON.parse(getWindowStorage('user'))?.config?.useGuideDownloadUrl)
    const role = getWindowStorage('role')

    const getMenuItem = (items, isShowIcon = true) => {
        // 当只有一个大菜单时 && 是管理侧用户，不走二级路由逻辑，所有二级菜单转成一级菜单
        if (items.length === 1 && role != UserRoleEnum.User && isShowIcon) {
            return items[0].children.map(item => {
                if (!item.hide && !hideMenu.includes(item.key)) {
                    return {
                        key: items[0].route + item.route,
                        icon: <Icon component={item.icon} />,
                        children: item.children ? getMenuItem(item.children, false) : null,
                        label: item.children ? (
                            item.title
                        ) : (
                            <Link to={items[0].route + item.route}>
                                <span>{item.title}</span>
                            </Link>
                        ),
                    }
                }
            })
        } else {
            return items.map((item: any) => {
                if (!item.hide && !hideMenu.includes(item.key)) {
                    if (item.closeChildrenMenu) {
                        // 一级路由和二级路由共存
                        return {
                            key: item.route + item.children[0].route,
                            icon: isShowIcon ? <Icon component={item.icon} /> : null,
                            label: (
                                <Link to={item.route + item.children[0].route}>
                                    <span>{item.title}</span>
                                </Link>
                            ),
                        }
                    } else {
                        let { parentFix = '' } = item
                        return {
                            key: parentFix + item.route,
                            icon: isShowIcon ? <Icon component={item.icon} /> : null,
                            children: item.children ? getMenuItem(item.children, false) : null,
                            label: item.children ? (
                                item.title
                            ) : item.mode === 'blank' ? (
                                <a
                                    href={item.route === '/redirect' ? url : item.route}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item.title}
                                </a>
                            ) : (
                                <Link to={parentFix + item.route}>
                                    <span>{item.title}</span>
                                </Link>
                            ),
                        }
                    }
                }
            })
        }
    }
    const path = props.match.path
    return (
        <Menu
            defaultOpenKeys={[`/${path.split('/')[1]}`]}
            theme={'dark'}
            selectedKeys={[props.location.pathname]}
            mode="inline"
            className={role === UserRoleEnum.Adminer ? 'sideBar admin' : 'sideBar'}
            items={getMenuItem(routeArr)}
        ></Menu>
    )
})
function SiderMenu(props) {
    const [collapsed, setcollapsed] = useState(false)
    const role = getWindowStorage('role')
    const adminClass = role === UserRoleEnum.Adminer ? 'admin' : ''
    return (
        <Sider trigger={null} collapsible width={256} collapsed={collapsed} className={adminClass}>
            <section className={`logo ${adminClass}`}>
                {isDevOrTest ? <span className="envtag">test</span> : null}
            </section>
            <SiderCustom {...props} />
            <span className="collapsedBtn" onClick={() => setcollapsed(!collapsed)}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </span>
        </Sider>
    )
}

export default withRouter(ManageSkeleton)
