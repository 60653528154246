import React from 'react'
import ReactDOM from 'react-dom'
import {
    registerMicroApps,
    addGlobalUncaughtErrorHandler,
    start,
    runAfterFirstMounted,
} from 'qiankun'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { subAppEnum } from './router/config'
import store from './redux/store'
import './index.scss'

function render({ loading, appContent = null }) {
    ReactDOM.render(
        // <React.StrictMode>
        <App loading={loading} content={appContent} />,
        // </React.StrictMode>,
        document.getElementById('main-root'),
    )
}
render({ loading: true })
const loader = loading => render({ loading })
const isDev = process.env.NODE_ENV === 'development'
registerMicroApps(
    [
        {
            name: 'avatarx',
            entry: isDev ? '//localhost:6040/' : window.__PATH_CONFIG_QIANKUN__.avatarxUrl,
            container: '.contentWrap .page-content',
            activeRule: subAppEnum.avatarx,
            props: {
                mainStore: store,
            },
            loader,
        },
        // {
        //     name: 'government',
        //     entry: '//geek.shanyue.tech',
        //     container: '.contentWrap .page-content',
        //     activeRule: '/platformgov',
        //     loader,
        // },
    ],
    {
        beforeLoad: [
            async app => {
                console.log('[LifeCycle] before load %c%s', 'color: green;', app.name)
            },
        ],
        beforeMount: [
            async app => {
                console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
            },
        ],
        afterUnmount: [
            async app => {
                console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
            },
        ],
    },
)
// setDefaultMountApp('/avatarx')
/**
 * 添加全局的未捕获异常处理器
 */
addGlobalUncaughtErrorHandler((event: Event & { message: string }) => {
    console.error(event)
    // 加载失败时提示
    if (event?.message?.includes('died in status LOADING_SOURCE_CODE')) {
        console.error('微应用加载失败，请检查应用是否可运行')
    }
})
start()
// {
//     fetch: request,
// },
runAfterFirstMounted(() => {
    console.log('基座已经启动，准备起飞')
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
reportWebVitals()
