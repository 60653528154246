import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './redux/store'
import RouteConfig from './router/index'
import store from './redux/store'
import './App.scss'
import { getWindowStorage, httpReq } from './utils/tools'
import { INIT_USER_CONFIG } from './redux/UserConfig/type'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/locale/zh_CN'
import ErrorPage from './components/Global/ErrorPage/ErrorPage'
import WithErrorCatch from './components/Global/WithHoc/WithErrorCatch'

console.info(
    `%c Micro-Frontend-Main v${process.env.PACKAGE_VERSION} `,
    `font-size: 20px;background: #e454d1; color: #fff`,
)

interface AppProps {
    loading: boolean
    content?: any
}

interface AppState {}

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props)
        this.state = {}
    }
    static getDerivedStateFromProps(props, state) {
        const token = getWindowStorage('token')
        const user = getWindowStorage('user')
        if (user && token && !history.location.pathname.includes('/login')) {
            // 有token去请求用户信息，请求的用户信息存在store下
            store.dispatch({
                type: INIT_USER_CONFIG,
                data: JSON.parse(user),
            })
        }
        return null
    }

    render() {
        return (
            <ConfigProvider locale={locale}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <RouteConfig loading={this.props.loading}></RouteConfig>
                    </ConnectedRouter>
                </Provider>
            </ConfigProvider>
        )
    }
}

export default WithErrorCatch(App)
