import React, { ComponentType } from 'react'
import ErrorPage from '../ErrorPage/ErrorPage'

export default function WithErrorCatch(Comp: ComponentType): React.ComponentClass<any> {
    return class WithErrorCatch extends React.Component {
        displayName = Comp.displayName
        state = {
            error: '',
        }
        componentDidCatch(error, info) {
            this.setState({
                error: `${error?.toString()} ${info.componentStack || ''}`,
            })
        }
        render() {
            let { error } = this.state
            if (this.state.error) return <ErrorPage error={error} />
            return <Comp {...this.props} />
        }
    }
}
