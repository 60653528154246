import React, { useMemo } from 'react'
import { Layout, Popover } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { logOutHandler } from 'src/utils/tools'
import './HeaderCustom.scss'

interface HeaderBarPropsType {
    history: any
    user: any
}

const defaultIcon = require('./images/defaultuser.png')

const HeaderBar = React.memo<HeaderBarPropsType>(props => {
    const Content = useMemo(() => {
        const logout = () => {
            logOutHandler()
        }
        return (
            <ul className="customPopover">
                <li onClick={logout}>
                    <LogoutOutlined />
                    退出
                </li>
            </ul>
        )
    }, [])

    return (
        <Layout.Header className="custom-theme">
            <div className="flex">
                <Popover placement="bottom" content={Content}>
                    <div className="userInfo">
                        <img src={defaultIcon} alt="用户头像" />
                        <p>{props.user.userName || '未设置名称'}</p>
                    </div>
                </Popover>
            </div>
        </Layout.Header>
    )
})

export default connect((state: any /* { loginReducer: StoreEditVideoState } */) => {
    return {
        user: {
            userName: state.userConfigStore.userInfo.username,
        },
    }
})(HeaderBar)
