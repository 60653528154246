import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const Breadcrumbs = props => {
    const { breadCrumbs } = props
    return (
        <div className="breadcrumbs">
            {breadCrumbs?.map(bread => {
                return (
                    <Link to={bread.path} key={bread.name}>
                        {bread.name}
                    </Link>
                )
            })}
        </div>
    )
}

export default connect((state: any) => {
    return {
        breadCrumbs: state.breadCrumbsStore,
    }
})(Breadcrumbs)
