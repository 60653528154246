import { createStore, combineReducers, applyMiddleware } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router' // redux中可以使用router
import { createBrowserHistory } from 'history' // redux中可以使用router
import { userConfigStore } from './UserConfig/reducer'
import { breadCrumbsStore } from './BreadCrumbsConfig/reducer'
import thunk from 'redux-thunk'

export const history = createBrowserHistory()
const actionsHistory = routerMiddleware(history)
const obj = {
    router: connectRouter(history), // root reducer with router state
    userConfigStore,
    breadCrumbsStore,
}
// let middleware = [sagaMiddleware, actionsHistory]
let store = createStore(
    combineReducers(obj),
    applyMiddleware(actionsHistory, thunk), // for dispatching history actions
)
export default store
