import React from 'react'
import './ErrorPage.scss'

export default function ErrorPage(props: { error: string }) {
    let { error } = props
    return (
        <div className="errorPage">
            <div className="iron-man">
                <img src={require('./images/iron_man.png')} alt="ỉronman" />
            </div>
            <div className="notify">
                <h3>抱歉,迷路了!</h3>
                <p>{error}</p>
                <h4>
                    <a href="/">返回首页</a>
                </h4>
                {/* <button>Go Back!</button> */}
            </div>
        </div>
    )
}
