import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
// import { connect } from 'react-redux'
import { Input, Form, Button, Checkbox, Col, Row } from 'antd'
import history from 'history'
import Icon from '@ant-design/icons'
import { getWindowStorage, httpReq, setWindowStorage, urlSearch } from '../../utils/tools'
import './Login.scss'
import { accountImg, logo, passwordImg } from './images/loginImg'
import store from 'src/redux/store'
import { INIT_USER_CONFIG } from 'src/redux/UserConfig/type'
import { UserRoleEnum } from 'src/router/enumType'
import routeConfig, { subAppEnum } from 'src/router/config'
export interface LoginProps {
    history: any
    location: history.Location
}
const Login = memo(function Login(props: LoginProps) {
    const [autoLoginState, setAutoLoginState] = useState(false)
    const tokenLogin = useRef(false)
    // token 登录
    let tokenParamsObj = urlSearch(props.location.search)
    if (tokenParamsObj.role && tokenParamsObj.token && !tokenLogin.current) {
        tokenLogin.current = true
        console.log('tokenParamsObj.referer', tokenParamsObj.referer)
        // 直接记住登录
        setWindowStorage('token', tokenParamsObj.token)
        setWindowStorage('role', tokenParamsObj.role)
        setWindowStorage('loginReferer', tokenParamsObj.referer)
    }
    // 账号密码登录
    const token = getWindowStorage('token')
    useEffect(() => {
        document.title = '登录...'
    }, [])
    const loginFn = useCallback(
        value => {
            const obj = {
                ...value,
                // port: window.location.port,
            }
            httpReq({
                url: '/api/user/login',
                methods: 'post',
                data: obj,
            }).then(res => {
                const identity = res.data.role
                const resToken = res.data.token

                let currentStorage
                if (autoLoginState) {
                    currentStorage = window.localStorage
                } else {
                    currentStorage = window.sessionStorage
                }
                currentStorage.setItem('token', resToken)
                currentStorage.setItem('role', identity)
                getUserInfo(identity, props.history)
            })
        },
        [autoLoginState],
    )
    const getInfoLoading = useRef(false)
    const getUserInfo = useCallback(
        (identity, history) => {
            if (getInfoLoading.current) return
            getInfoLoading.current = true
            const storageName =
                tokenLogin.current || autoLoginState ? 'localStorage' : 'sessionStorage'
            let userInfo
            if (identity !== UserRoleEnum.User) {
                let map = {
                    [UserRoleEnum.Adminer]: 'admin',
                    [UserRoleEnum.Saleman]: 'saleman',
                    [UserRoleEnum.Approver]: 'approver',
                }
                httpReq({
                    url: `/api/${map[identity] || 'admin'}/user/info`,
                    method: 'get',
                })
                    .then(res => {
                        userInfo = res.data
                        setWindowStorage('user', JSON.stringify(userInfo), storageName)
                        store.dispatch({
                            type: INIT_USER_CONFIG,
                            data: userInfo,
                        })
                        history.replace('/')
                    })
                    .finally(() => {
                        getInfoLoading.current = false
                    })
            } else {
                httpReq({
                    url: '/api/app/user/info',
                    method: 'get',
                })
                    .then(res => {
                        userInfo = res.data
                        store.dispatch({
                            type: INIT_USER_CONFIG,
                            data: userInfo,
                        })
                        const hideMenu = []
                        for (let k in userInfo?.MenusSwitch) {
                            if (userInfo?.MenusSwitch?.[k] === 'OFF') {
                                hideMenu.push(k)
                            }
                        }
                        setWindowStorage('user', JSON.stringify({ ...userInfo }), storageName)
                        history.replace('/')
                    })
                    .finally(() => {
                        getInfoLoading.current = false
                    })
            }
        },
        [autoLoginState],
    )
    if (token) {
        // 有token去请求用户信息，请求的用户信息存在store下
        let userId = getWindowStorage('role')
        getUserInfo(userId, props.history)
        return null
    } else {
        return (
            <div className="loginWrap">
                <div className="loginForm">
                    <section className="inputWrap">
                        <Icon component={logo} style={{ marginBottom: '60px' }}></Icon>
                        <NormalLoginForm
                            {...props}
                            autoLoginState={autoLoginState}
                            setAutoLoginState={setAutoLoginState}
                            loginFn={loginFn}
                        />
                    </section>
                    <div className="login-img"></div>
                </div>
            </div>
        )
    }
})

function NormalLoginForm(props) {
    const { autoLoginState, setAutoLoginState, loginFn } = props

    return (
        <Form name="normal_login" onFinish={loginFn} className="login-form">
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: '请输入账户!',
                    },
                    {
                        type: 'string',
                        message: '请输入合法名称!',
                    },
                    {
                        max: 20,
                        message: '最多输入20字符!',
                    },
                ]}
            >
                <Input
                    prefix={<Icon component={accountImg}></Icon>}
                    maxLength={20}
                    allowClear
                    placeholder="账户"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: '请输入您的密码!',
                    },
                    {
                        max: 15,
                        message: '密码最多输入15字符!',
                    },
                ]}
            >
                <Input
                    prefix={<Icon component={passwordImg}></Icon>}
                    maxLength={15}
                    allowClear
                    type="password"
                    placeholder="密码"
                />
            </Form.Item>
            <Form.Item>
                <Row className="login-tips" align="middle" justify="start">
                    <Col>
                        <Checkbox
                            checked={autoLoginState}
                            onChange={evt => {
                                setAutoLoginState(evt.target.checked)
                            }}
                        >
                            自动登录
                        </Checkbox>
                    </Col>
                </Row>
            </Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
                登录
            </Button>
        </Form>
    )
}
function validateToPassword(rule, value) {
    if (value.length > 10) {
        return false
    }
}
export default Login
