// api_packageid 当前用户套餐等级
export interface userConfigType {}
const defaultState = []

export const breadCrumbsStore = (state = defaultState, action): userConfigType => {
    const data = action.data
    switch (action.type) {
        case 'INIT_BREADCRUMBS':
            return data
        case 'PUSH_BREADCRUMBS':
            return [{ name: 'qwer' }]
        case 'CLEAR_BREADCRUMBS':
            return []
        default:
            return state as any
    }
}
