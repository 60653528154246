import { ComponentType } from 'react'
import {
    accountSvg,
    appManageIcon,
    appSvg,
    artIcon,
    artManageIcon,
    callIcon,
    userSearchIcon,
    portDoc,
    userDoc,
    liveAutoIcon,
    audioCustServiceIcon,
    avatarCloudIcon,
    satellite_icon,
    interact_icon,
    template_icon,
} from 'src/assets/Svg'
import { UserRoleEnum } from './enumType'

export enum subAppEnum {
    avatarx = '/avatarx',
    government = '/platformgov',
}
export type routeConfigType = {
    route: string
    title: string
    key?: string
    mode?: string
    icon?: () => JSX.Element
    role: UserRoleEnum[]
    hide?: boolean
    component?: ComponentType<any>
    children?: routeConfigType[]
    closeChildrenMenu?: boolean
}
const routeConfig: {
    [key: string]: routeConfigType[]
} = {
    [subAppEnum.avatarx]: [
        // 菜单相关路由
        {
            route: '/app',
            title: '应用管理',
            key: 'AppManage',
            icon: appManageIcon,
            role: [UserRoleEnum.User],
            closeChildrenMenu: true,
            children: [
                {
                    route: '/application',
                    title: '应用管理',
                    icon: appManageIcon,
                    role: [UserRoleEnum.User],
                },
            ],
        },
        {
            route: '/manage',
            title: 'Avatar云平台',
            key: 'AvatarCloudPlatform',
            icon: avatarCloudIcon,
            role: [UserRoleEnum.User, UserRoleEnum.Adminer],
            children: [
                // 管理
                {
                    route: '/user_manage',
                    title: '用户管理',
                    icon: accountSvg,
                    role: [UserRoleEnum.Adminer],
                },
                {
                    route: '/user_option',
                    title: '用户编辑',
                    icon: accountSvg,
                    role: [UserRoleEnum.Adminer],
                    hide: true,
                },
                {
                    route: '/user_search',
                    title: '用户数据查询',
                    icon: userSearchIcon,
                    role: [UserRoleEnum.Adminer],
                },
                {
                    route: '/apistatistics',
                    title: '调用统计',
                    icon: callIcon,
                    role: [UserRoleEnum.Adminer],
                },
                {
                    route: '/art_admin',
                    title: '美术素材',
                    icon: artManageIcon,
                    role: [UserRoleEnum.Adminer],
                    children: [
                        {
                            route: '/materialManage',
                            title: '素材管理',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/materialUpdate',
                            title: '素材更新',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/materialPre',
                            title: '预发布管理',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/materialProd',
                            title: '发布管理',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/materialProd/editMaterial',
                            title: '编辑素材属性',
                            role: [UserRoleEnum.Adminer],
                            hide: true,
                        },
                    ],
                },
                // {
                //     route: '/user_create',
                //     title: '用户配置',
                //     icon: artManageIcon,
                //     role: [UserRoleEnum.Adminer],
                //
                // },
                {
                    route: '/sate_admin',
                    title: '卫星应用管理',
                    icon: satellite_icon,
                    role: [UserRoleEnum.Adminer],
                },
                {
                    route: '/liveDataBoard',
                    title: '直播数据看板',
                    role: [UserRoleEnum.Adminer],
                    icon: accountSvg,
                    // hide: true,
                    children: [
                        {
                            route: '/productExperience',
                            title: '产品体验统计',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/merchantUsage',
                            title: '商家使用统计',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/commercializationAnalysis',
                            title: '商业化分析',
                            role: [UserRoleEnum.Adminer],
                        },
                        {
                            route: '/userDetail',
                            title: '账号详情',
                            role: [UserRoleEnum.Adminer],
                            hide: true,
                        },
                    ],
                },
                {
                    route: '/interact_option',
                    title: '互动配置管理',
                    icon: interact_icon,
                    role: [UserRoleEnum.Adminer],
                },
                {
                    route: '/merchant_template',
                    title: '商家模板',
                    icon: template_icon,
                    role: [UserRoleEnum.Adminer],
                },
                // 用户
                {
                    route: '/account',
                    title: '账户概览',
                    icon: accountSvg,
                    role: [UserRoleEnum.User],
                },
                // {
                //     route: '/application',
                //     title: '应用管理',
                //     icon: appManageIcon,
                //     role: [UserRoleEnum.User],
                //
                // },
                {
                    route: '/art',
                    title: '美术素材',
                    icon: artIcon,
                    role: [UserRoleEnum.User],
                },
                {
                    route: '/materialOperation',
                    title: '素材运营',
                    icon: artManageIcon,
                    role: [UserRoleEnum.User],
                },
                {
                    route: '/overview',
                    title: '模板概览',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
                },
                {
                    route: '/redirect',
                    mode: 'blank',
                    title: '使用指南',
                    icon: userDoc,
                    role: [UserRoleEnum.User],
                },
                {
                    route: '/document/',
                    mode: 'blank',
                    title: '接口文档',
                    icon: portDoc,
                    role: [UserRoleEnum.User],
                },
            ],
        },
        // Saleman  销售卫星应用
        {
            route: '/sales',
            title: '销售端',
            key: 'sales-man',
            role: [UserRoleEnum.Saleman],
            children: [
                {
                    route: '/sate_user',
                    title: '卫星应用申请',
                    icon: accountSvg,
                    role: [UserRoleEnum.Saleman],
                },
                {
                    route: '/sate_apply_record',
                    title: '卫星应用申请记录',
                    icon: satellite_icon,
                    role: [UserRoleEnum.Saleman],
                },
            ],
        },
        // Approver 审核人 卫星应用
        {
            route: '/audit',
            title: '销售端',
            key: 'sales-man',
            role: [UserRoleEnum.Approver],
            children: [
                {
                    route: '/audit_list',
                    title: '审核列表',
                    icon: satellite_icon,
                    role: [UserRoleEnum.Approver],
                },
                {
                    route: '/sate_user',
                    title: '卫星应用申请',
                    icon: accountSvg,
                    hide: true,
                    role: [UserRoleEnum.Approver],
                },
            ],
        },
        {
            route: '/liveAuto',
            title: '虚拟人直播（自动）',
            key: 'LiveAutomatic',
            icon: liveAutoIcon,
            role: [UserRoleEnum.User],
            children: [
                {
                    route: '/accountManage',
                    title: '账号管理',
                    icon: appSvg,
                    key: 'LiveAutomaticAccountManage',
                    role: [UserRoleEnum.User],
                },
                {
                    route: '/deviceManage',
                    title: '设备管理',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
                },
            ],
        },
        /*   {
            route: '/liveMotionCapture',
            title: '虚拟人直播（动捕）',
            key: 'LiveMotionCapture',
            icon: liveAutoIcon,
            role: [UserRoleEnum.User],
            children: [
                {
                    route: '/accountManage',
                    title: '账号管理',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
        
                },
                {
                    route: '/deviceManage',
                    title: '设备管理',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
        
                },
            ],
        }, */
        {
            route: '/audioDriverService',
            title: '音频驱动客服',
            key: 'AudioCustomerService',
            icon: audioCustServiceIcon,
            role: [UserRoleEnum.User],
            children: [
                {
                    route: '/accountManage',
                    title: '账号管理',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
                },
                {
                    route: '/deviceManage',
                    title: '设备管理',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
                },
            ],
        },
        {
            route: '/interact',
            title: '互动配置',
            key: 'WebSocket',
            icon: interact_icon,
            role: [UserRoleEnum.User],
            children: [
                {
                    route: '/statistic',
                    title: '数据统计',
                    icon: appSvg,
                    role: [UserRoleEnum.User],
                },
            ],
        },
    ],
    // [subAppEnum.government]: [
    //     {
    //         route: '/government',
    //         title: '测试其他网页',
    //         key: 'government',
    //         icon: interact_icon,
    //         role: [UserRoleEnum.User, UserRoleEnum.Adminer],
    //     },
    // ],
}

export default routeConfig

/********** 逃逸路由 *************/
//不需要登录，并且不需要公共的sideBar 和header
export const escapeRouteConfig = {
    [subAppEnum.avatarx]: [
        {
            route: '/live',
            title: '直播客户端内嵌',
            key: 'LiveAppInnerWebView',
            children: [
                {
                    route: '/template_choose',
                    title: '商家模板',
                    icon: appSvg,
                },
            ],
        },
    ],
}
