import { INIT_USER_CONFIG } from './type'
// api_packageid 当前用户套餐等级

export interface userConfigType {
    userInfo: {
        companyName: string
        username: string
        apiPackageid: number // 用户套餐等级
        materialPackageid: number
        packageStartDate: string
        packageEndDate: string
        type: string
    }
    userPackageMap: { id: number; typeName: string }[]
    userType: { type: string; value: string }[]
    materialPackageMap: { id: number; typeName: string }[]
    canGenerateMap: { id: number; name: string }[]
    apisList: string[]
    userCreateDay?: string
    MenusSwitch?: {
        AudioCustomerService: 'ON' | 'OFF'
        AvatarCloudPlatform: 'ON' | 'OFF'
        LiveAutomatic: 'ON' | 'OFF'
        LiveMotionCapture: 'ON' | 'OFF'
    }
    userApps: {
        [k in 'AudioCustomerService' | 'LiveAutomatic' | 'LiveMotionCapture']: {
            start: string
            end: string
            status: 'ON' | 'OFF'
        }
    }
}
const defaultState = {
    userInfo: {} as any,
    userPackageMap: [],
    materialPackageMap: [],
    canGenerateMap: [],
    userType: [],
    userCreateDay: '',
    MenusSwitch: {},
    userApps: {},
}
export const userConfigStore = (state = defaultState, action): userConfigType => {
    switch (action.type) {
        case INIT_USER_CONFIG:
            const { config = {}, user, MenusSwitch, userApps } = action.data
            const { apis, apiPackageDetail, materialPackageDetail, types, systemStartDay } = config
            const {
                api_packageid,
                material_packageid,
                username,
                startDate,
                endDate,
                companyName,
                type,
            } = user
            const userInfo = {
                companyName,
                username,
                apiPackageid: api_packageid, // 用户套餐等级
                materialPackageid: material_packageid,
                packageStartDate: startDate,
                packageEndDate: endDate,
                type,
            }
            const apisList = apis?.map(item => {
                return item.name
            })
            return {
                userInfo,
                canGenerateMap: apis,
                userPackageMap: apiPackageDetail,
                materialPackageMap: materialPackageDetail,
                apisList,
                userType: types,
                userCreateDay: systemStartDay,
                MenusSwitch,
                userApps,
            }
        default:
            return state as any
    }
}
