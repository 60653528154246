export enum UserRoleEnum {
    // Applicant = 'app', // 使用人  // 待废弃
    User = 'User',
    // 超级管理员
    Adminer = 'Adminer',
    // 销售
    Saleman = 'Saleman',
    // 技术支持
    TechnicalSupport = 'TechnicalSupport',
    // 素材资源管理员
    ResourceAdminer = 'ResourceAdminer',
    // 用户审核人员
    Approver = 'Approver',
}
